<template>
  <div class="InvoiceCenter container">
    <!-- 常用抬头信息 -->
    <div class="Invoice-info">
      <div class="info-one">
        <span>常用发票抬头</span>
        <button @click="InvoiceChangeFun()">添加</button>
      </div>
      <div v-for="(item) in billList" :key="item.id" class="Invoice-info-item">
        <div class="item-content">
          <span>{{ item.billTitle }}</span>
          <span>{{ item.billType === 1 ? '企业单位' : '个人/非企业用户' }}</span>
        </div>
        <div class="item-fun">
          <span @click="deleteInvoiceFun(item.id)">删除</span>
          <span @click="InvoiceChangeFun(item)">修改</span>
        </div>
      </div>
      <!-- <div class="Invoice-info-item">
        <div class="item-content">
          <span>厨窗加</span>
          <span>个人/非企业用户</span>
        </div>
        <div class="item-fun">
          <span>删除</span>
          <span>修改</span>
        </div>
      </div> -->
    </div>
    <!-- 常用抬头信息 -->
    <!-- 常用抬头表格 -->
    <div class="Invoice">
      <h4 class="Invoice-title">常用发票抬头</h4>
      <div class="choose-Invoice">
        <a-radio-group v-model:value="InvoiceType">
          <a-radio :value="1">电子普通发票</a-radio>
          <a-radio :value="2">增值税专用发票</a-radio>
        </a-radio-group>
      </div>
      <div class="invoice-tips">
        <InfoCircleOutlined class="db-mr8 db-ml8" />
        <span>温馨提示：增值税发票开票周期约为3-5个工作日，为避免影响报账，请您及时提交开票！如有问题可咨询在线客服</span>
      </div>
      <div class="search-fun">
        <!-- <div class="search-fun-item">
          <span>订单分类</span>
          <a-select
            ref="select"
            v-model:value="orderType"
            style="width: 100px; margin-left: 16px;"
            :options="orderTypeList"
          />
        </div> -->
        <div class="search-fun-item">
          <span>支付方式</span>
          <a-select
            ref="select"
            v-model:value="payType"
            style="width: 100px; margin-left: 16px;"
            :options="payTypeList"
            @change="payTypeFun"
          />
        </div>
        <a-config-provider :locale="locale === 'en' ? zhCN : zhCN">
          <div class="search-fun-item">
            <span class="db-mr8">订单日期</span>
            <a-range-picker format="YYYY-MM-DD" @change="rangePickerChange" />
          </div>
        </a-config-provider>
      </div>

      <div class="Invoice-table">
        <a-table
          :row-key="record => record.orderId"
          :row-selection="rowSelection"
          :data-source="dataSource"
          :columns="columns"
          :pagination="false"
        />
        <div class="footer">
          <span>合计金额</span>
          <span>￥{{ totalAmount }}</span>
          <a-button @click="applyInvoice">开票</a-button>
        </div>
      </div>
      <!-- 开票历史 -->
      <h4 class="Invoice-title">发票历史</h4>
      <div class="Invoice-table">
        <a-table
          :row-key="record => record.orderId"
          :data-source="dataSourceHis"
          :columns="columns"
          :pagination="{
            style: {
              'margin-right': '20px'
            }
          }"
        >
          <template #action="{ }">
            <div class="action-btn">
              <button>申请换开</button>
              <button>详情</button>
            </div>
          </template>
        </a-table>
      </div>
      <!-- 开票历史 -->
    </div>
    <!-- 常用抬头表格 -->

    <!-- 修改发票抬头信息弹窗 -->
    <a-modal
      v-model:visible="showInvoiceModal"
      width="1000px"
      :footer="null"
      :body-style="{
        padding: '64px 160px'
      }"
      @cancel="closeInvoiceChange"
    >
      <div class="Invoice-Modal">
        <h5 class="Invoice-Modal-title">{{ billform.id ? '编辑' : '新增' }}发票抬头</h5>
        <div class="InvoiceModal-form" style="border: none;">
          <span class="InvoiceModal-form-name">抬头类型</span>
          <a-radio-group v-model:value="billform.billType">
            <a-radio :value="1">企业单位</a-radio>
            <a-radio :value="2">个人/非企业单位</a-radio>
          </a-radio-group>
        </div>
        <div class="InvoiceModal-form">
          <span class="InvoiceModal-form-name Required">公司名称</span>
          <a-input v-model:value="billform.billTitle" class="InvoiceModal-form-input" allow-clear type="text" placeholder="请输入公司名称" />
        </div>
        <div class="InvoiceModal-form">
          <span class="InvoiceModal-form-name Required">公司税号</span>
          <a-input v-model:value="billform.billTax" class="InvoiceModal-form-input" allow-clear type="text" placeholder="请输入公司税号" />
        </div>
        <div class="InvoiceModal-form">
          <span class="InvoiceModal-form-name Required">注册地址</span>
          <a-input v-model:value="billform.billAddress" class="InvoiceModal-form-input" allow-clear type="text" placeholder="请输入注册地址" />
        </div>
        <div class="InvoiceModal-form">
          <span class="InvoiceModal-form-name Required">注册电话</span>
          <a-input v-model:value="billform.billPhone" class="InvoiceModal-form-input" allow-clear type="text" placeholder="请输入注册电话" />
        </div>
        <div class="InvoiceModal-form">
          <span class="InvoiceModal-form-name Required">开户银行</span>
          <a-input v-model:value="billform.billBank" class="InvoiceModal-form-input" allow-clear type="text" placeholder="请输入开户银行" />
        </div>
        <div class="InvoiceModal-form">
          <span class="InvoiceModal-form-name Required">银行行号</span>
          <a-input v-model:value="billform.billBankNumber" class="InvoiceModal-form-input" allow-clear type="text" placeholder="请输入银行行号" />
        </div>
        <div class="InvoiceModal-form" style="border: none;">
          <span class="InvoiceModal-form-name">是否默认</span>
          <a-checkbox v-model:checked="defaultInvoice" />
        </div>

        <div class="InvoiceModal-btn">
          <a-button @click="confirmFun">确定</a-button>
          <a-button @click="closeInvoiceChange">取消</a-button>
        </div>
      </div>

    </a-modal>
    <!-- 修改发票抬头信息弹窗 -->
  </div>
</template>

<script>
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import { mapState } from 'vuex'
import {
  getMemberBillList,
  deleteInvoice,
  updateInvoice,
  saveInvoice,
  getOrderBillAll,
  getOrderBill
} from '@/api/invoice'
import enUS from 'ant-design-vue/es/locale/en_US'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import moment from 'moment'
import 'moment/dist/locale/zh-cn'
import { filterVal } from '@/utils'
moment.locale('zhCN')
const billform = {
  id: '',
  memberId: '',
  billType: 1,
  billTitle: '',
  billTax: '',
  billAddress: '',
  billPhone: '',
  billBank: '',
  billBankNumber: ''
}
export default {
  name: 'InvoiceCenter',
  components: {
    InfoCircleOutlined
  },

  data() {
    return {
      orderTypeList: [
        {
          value: '1',
          label: '1'
        },
        {
          value: '2',
          label: '2'
        },
        {
          value: '3',
          label: '3'
        },
        {
          value: '4',
          label: '4'
        }
      ],
      payTypeList: [
        {
          value: 0,
          label: '线上支付'
        },
        {
          value: 1,
          label: '线下支付'
        }
      ],
      InvoiceType: 1,
      orderType: '',
      payType: 0,
      orderTime: [],
      dataSource: [],
      dataSourceHis: [],
      columns: [
        {
          title: '订单编号',
          dataIndex: 'orderSn',
          key: 'orderSn'
        },
        {
          title: '发票状态',
          dataIndex: 'status',
          key: 'status',
          customRender: (text) => {
            return filterVal(text.text, this.statusList, 'value', 'text')
          }
        },
        {
          title: '支付时间',
          dataIndex: 'paymentTime',
          key: 'paymentTime'
        },
        {
          title: '支付方式',
          dataIndex: 'payType',
          key: 'payType',
          customRender: (text) => {
            return filterVal(text.text, this.payTypeList)
          }
        },
        {
          title: '可开票金额',
          dataIndex: 'payAmount',
          key: 'payAmount',
          sorter: (a, b) => a.KP_price - b.KP_price
        },
        {
          title: '操作',
          dataIndex: 'address',
          key: 'address',
          slots: {
            customRender: 'action'
          }
        }
      ],
      statusList: [
        { text: -1, value: '未申请' },
        { text: 0, value: '审核中' },
        { text: 1, value: '审核通过' },
        { text: 2, value: '发票已开' }
      ],
      // -1 未申请，0->审核中，1->审核通过；2->发票已开'
      dateFormat: 'YYYY/MM/DD',
      showInvoiceModal: false,
      defaultInvoice: false,
      locale: zhCN.locale,
      enUS,
      zhCN,
      moment,
      rowSelection: {
        onChange: this.onSelectChange
      },
      billform: Object.assign({}, billform),
      billList: [],
      selectList: []
    }
  },
  computed: {
    ...mapState({
      User_ID: state => state.user.id
    }),
    totalAmount() {
      const totalAmount = this.selectList.reduce((cur, item) => {
        cur += item.payAmount
        return cur
      }, 0)
      return totalAmount
    }
  },
  created() {
    this.getInvoiceList()
    this.getOrderBillAllFun()
    this.getOrderBillFun()
  },
  methods: {
    rangePickerChange(val, dateString) {
      this.orderTime = dateString
      this.getOrderBillAllFun()
    },
    payTypeFun(e) {
      this.getOrderBillAllFun()
    },
    onSelectChange(id, row) {
      this.selectList = row
    },
    applyInvoice() {
      if (this.selectList.length === 0) {
        this.$message.warning('请选择要开票的订单')
        return
      }
      this.$router.push({ path: '/user/applyInvoice', query: { data: JSON.stringify(this.selectList) }})
    },
    InvoiceChangeFun(data) {
      if (data) {
        this.billform = data
        this.defaultInvoice = data.defaultStatus === 1
      }
      this.showInvoiceModal = !this.showInvoiceModal
    },
    closeInvoiceChange() {
      this.showInvoiceModal = false
      this.initializForm()
    },

    confirmFun() {
      if (!this.checkRequire()) return
      if (this.billform.id) {
        this.updateInvoiceFun()
      } else this.saveInvoiceFun()
    },
    saveInvoiceFun() {
      const data = {
        memberId: this.User_ID,
        billType: this.billform.billType,
        billTitle: this.billform.billTitle,
        billTax: this.billform.billTax,
        billAddress: this.billform.billAddress,
        billPhone: this.billform.billPhone,
        billBank: this.billform.billBank,
        billBankNumber: this.billform.billBankNumber,
        defaultStatus: this.defaultInvoice ? 1 : 2
      }
      saveInvoice(data).then(res => {
        if (res.code === 0) {
          this.$message.success('新增成功')
          this.showInvoiceModal = false
          this.initializForm()
          this.getInvoiceList()
        }
      })
    },
    updateInvoiceFun() {
      const data = {
        id: this.billform.id,
        memberId: this.User_ID,
        billType: this.billform.billType,
        billTitle: this.billform.billTitle,
        billTax: this.billform.billTax,
        billAddress: this.billform.billAddress,
        billPhone: this.billform.billPhone,
        billBank: this.billform.billBank,
        billBankNumber: this.billform.billBankNumber,
        defaultStatus: this.defaultInvoice ? 1 : 2
      }
      updateInvoice(data).then(res => {
        this.$message.success('修改成功')
        this.showInvoiceModal = false
        this.initializForm()
        this.getInvoiceList()
      })
    },
    deleteInvoiceFun(id) {
      const data = []
      data.push(id)
      deleteInvoice(data).then(res => {
        this.$message.success('删除成功')
        this.getInvoiceList()
      })
    },
    getInvoiceList() {
      getMemberBillList().then(res => {
        this.billList = res.data
      })
    },
    getOrderBillAllFun() {
      const data = {
        payType: this.payType,
        beginTime: this.orderTime.length ? this.orderTime[0] : '',
        endTime: this.orderTime.length ? this.orderTime[1] : ''
      }
      getOrderBillAll(data).then(res => {
        this.dataSource = res.data
      })
    },
    getOrderBillFun() {
      getOrderBill().then(res => {
        this.dataSourceHis = res.data
      })
    },
    initializForm() {
      this.billform = Object.assign({}, billform)
      this.defaultInvoice = false
    },
    checkRequire() {
      let isCheck = false
      if (!this.billform.billTitle) {
        this.$message.error('公司名称不能为空')
        isCheck = false
      } else if (!this.billform.billTax) {
        this.$message.error('公司税号不能为空')
        isCheck = false
      } else if (!this.billform.billAddress) {
        this.$message.error('注册地址不能为空')
        isCheck = false
      } else if (!this.billform.billPhone) {
        this.$message.error('注册电话不能为空')
        isCheck = false
      } else if (!this.billform.billBank) {
        this.$message.error('开户银行不能为空')
        isCheck = false
      } else if (!this.billform.billBankNumber) {
        this.$message.error('银行行号不能为空')
        isCheck = false
      } else isCheck = true
      return isCheck
    },
    filterVal
  }
}

</script>
<style lang='less' scoped>
.InvoiceCenter {
  padding-bottom: 24px;
  // 常用发票信息
  .Invoice-info {
    padding: 64px 0;

    .info-one {
      margin-bottom: 20px;

      & > span {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        margin-right: 24px;
      }
      & > button {
        width: 56px;
        height: 24px;
        background: #3B6652;
        border-radius: 12px;
        color: #fff;
        text-align: center;
        line-height: 12px;
        font-size: 12px;
        cursor: pointer;
      }
    }

    .Invoice-info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0;
      border-bottom: 1px solid #F3F3F3;

      .item-content {
        span {
          display: inline-block;
          font-weight: 400;
        }
        & > span:first-child {
          color: #666666;
          font-size: 16px;
          margin-right: 24px;
        }
        & > span:last-child {
          padding: 1px 4px;
          height: 20px;
          background: #3B6652;
          color: #fff;
          font-size: 12px;
          text-align: center;
        }
      }
      .item-fun {
        span {
          font-size: 16px;
          color: #666666;
          font-weight: 400;
          cursor: pointer;
        }
        & > span:last-child {
          margin-left: 13px;
          color: #3B6652;
        }
      }
    }
  }
  // 常用发票信息

  // 常用发票表格
  .Invoice {
    .Invoice-title {
      color: #333333;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 24px;
    }
    .choose-Invoice {
      margin-bottom: 24px;
    }
    .invoice-tips {
      width: 1320px;
      height: 38px;
      background: #F9F1DD;
      // padding: 8px;
      margin-bottom: 64px;
      line-height: 38px;
      color: #666666;
      font-size: 16px;
      font-weight: 400;
    }

    .search-fun {
      display: flex;
      margin-bottom: 38px;

      .search-fun-item {
        margin-right: 48px;
      }
    }

    .Invoice-table {
      border: 1px solid #E5E5E5;
      margin-bottom: 64px;

      .action-btn {
        & > button {
          height: 20px;
          padding: 1px 12px;
          background-color: #3B6652;
          color: #FFFFFF;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          line-height: 12px;
          margin-right: 16px;
          border-radius: 12px;
        }
      }

      .footer {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > span {
          font-size: 18px;
          color: #333333;
          margin-right: 142px;
          font-weight: bold;
        }
        & > button {
          width: 128px;
          height: 32px;
          background: #3B6652;
          border-radius: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          line-height: 12px;
          margin-right: 16px;
        }
      }
    }
  }
  // 常用发票表格

}
 // 修改发票弹窗
.Invoice-Modal {

  .Invoice-Modal-title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 40px;
  }
  .InvoiceModal-form {
    display: flex;
    align-items: center;
    // margin-bottom: 24px;
    padding: 16px 0;
    border-bottom: 1px solid #F3F3F3;
    .InvoiceModal-form-input {
      width: 300px;
      border: none;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }

    .InvoiceModal-form-name {
      display: inline-block;
      width: 136px;
      color: #666666;
      font-weight: bold;
    }
  }
  .InvoiceModal-btn {
    display: flex;
    align-items: center;
    margin-top: 24px;

    & > button {
      width: 173px;
      height: 46px;
      opacity: 1;
      border-radius: 32px;
      font-size: 16px;
      text-align: center;
      line-height: 16px;
      // cursor: pointer;
    }
    & > button:first-child {
      background: #3B6652;
      color: #fff;
      margin-right: 147px;
    }
    & > button:last-child {
      border: 1px solid #E5E5E5;
      color: #999999;
    }
  }
  .Required {
    &::after {
      content: '*';
      color: #DF0404;
    }
  }
}

  // 修改发票弹窗

:deep(.ant-checkbox-indeterminate .ant-checkbox-inner::after) {
  background-color: #3B6652;
}
:deep(.ant-table-thead > tr > th) {
  background-color: #D7E0DC;
}
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}
</style>
