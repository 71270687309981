<template>
  <div class="container OrderCenter">
    <div class="order-bar">
      <div class="order-bar-item" @click="orderBarFun('全部订单')">
        <img v-show="newOrderBar !== '全部订单'" src="@/assets/images/icons/order.png" alt="order">
        <img v-show="newOrderBar === '全部订单'" src="@/assets/images/icons/order-s.png" alt="order">
        <span>全部订单</span>
      </div>
      <div class="order-bar-item" @click="orderBarFun('待支付')">
        <img v-show="newOrderBar !== '待支付'" src="@/assets/images/icons/zhifu.png" alt="zhifu">
        <img v-show="newOrderBar === '待支付'" src="@/assets/images/icons/zhifu-s.png" alt="zhifu">
        <span>待支付</span>
      </div>
      <div class="order-bar-item" @click="orderBarFun('进行中')">
        <img v-show="newOrderBar !== '进行中'" src="@/assets/images/icons/jinxin.png" alt="jinxin">
        <img v-show="newOrderBar === '进行中'" src="@/assets/images/icons/jinxin-s.png" alt="jinxin">
        <span>进行中</span>
      </div>
      <div class="order-bar-item" @click="orderBarFun('已完成')">
        <img v-show="newOrderBar !== '已完成'" src="@/assets/images/icons/wanc.png" alt="wanc">
        <img v-show="newOrderBar === '已完成'" src="@/assets/images/icons/wanc-s.png" alt="wanc">
        <span>已完成</span>
      </div>
      <div class="order-bar-item" @click="orderBarFun('售后中')">
        <img v-show="newOrderBar !== '售后中'" src="@/assets/images/icons/shouhou.png" alt="shouhou">
        <img v-show="newOrderBar === '售后中'" src="@/assets/images/icons/shouhou-s.png" alt="shouhou">
        <span>售后中</span>
      </div>
      <div class="order-bar-item" @click="orderBarFun('待评价')">
        <img v-show="newOrderBar !== '待评价'" src="@/assets/images/icons/pingjia.png" alt="pingjia">
        <img v-show="newOrderBar === '待评价'" src="@/assets/images/icons/pingjia-s.png" alt="pingjia">
        <span>待评价</span>
      </div>
    </div>

    <div class="order-list">
      <a-spin :spinning="isLoading" size="large">
        <orderItems :list="orderList" :title="newOrderBar" />
      </a-spin>
      <!-- <orderItems /> -->
    </div>
  </div>
</template>

<script>
import orderItems from '@/views/user/components/orderItems'
import { getOrderByStatus } from '@/api/order'
import { mapGetters } from 'vuex'
export default {
  name: 'OrderCenter',
  components: {
    orderItems
  },
  data() {
    return {
      newOrderBar: '全部订单',
      orderList: [],
      timer: null
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'loading/isLoading'
    })
  },
  mounted() {
    const barTitle = sessionStorage.getItem('orderItemBar', this.newOrderBar)
    this.orderBarFun(barTitle)
  },
  methods: {
    orderBarFun(val) {
      const _this = this
      let status = ''
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        switch (val) {
          case '全部订单':
            this.newOrderBar = '全部订单'
            status = ''
            break
          case '待支付':
            this.newOrderBar = '待支付'
            status = 0
            break
          case '进行中':
            this.newOrderBar = '进行中'
            status = 1
            break
          case '已完成':
            this.newOrderBar = '已完成'
            status = 2
            break
          case '售后中':
            this.newOrderBar = '售后中'
            status = 3
            break
          case '待评价':
            this.newOrderBar = '待评价'
            status = 3
            break
        }
        sessionStorage.setItem('orderItemBar', this.newOrderBar)
        const data = {
          status
        }
        _this.getOrder(data)
      }, 100)
    },
    getOrder(data) {
      getOrderByStatus(data).then(res => {
        this.orderList = res.data
      })
    }
  }
}
</script>
<style lang='less' scoped>
.OrderCenter {
  margin-bottom: 40px;

  .order-bar {
    width: 100%;
    height: 165px;
    display: flex;
    justify-content: center;
    align-items: center;

    .order-bar-item {
      width: 220px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      & > img {
        width: 80px;
        height: 80px;
        margin-bottom: 8px;
      }
      & > span {
        color: #999999;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
  .order-list {
    width: 100%;
    min-height: 572px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }
}
</style>
