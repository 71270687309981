<template>
  <div class="orderItems">
    <div v-for="item in list" :key="item.id" class="db-mb20">
      <div class="orderno">
        <span>订单编号：{{ item.orderSn }}</span>
        <!-- <span>包裹编号：OR12332412312</span> -->
        <div class="goods-num">
          <span>共{{ item.skuQuantity }}件</span>
        </div>
        <div class="order-btn-box">
          <span>{{ filterVal(item.status, statusList, 'label', 'value') }}</span>
        </div>
      </div>

      <div v-if="iSeeMoreGoods === item.id" class="order-info order-info-hidden">
        <div v-for="(citem, cindex) in itemsList" :key="citem.skuId" class="order-info-item">
          <div class="order-item-index">{{ cindex + 1 }}</div>
          <div class="order-img">
            <img v-lazy="citem.skuPic" alt="goods">
          </div>
          <div class="info-box">
            <div class="info-name blod db-mb16">{{ citem.spuName }}</div>
            <div class="info-name db-mb16">{{ citem.skuName }}</div>
            <!-- <div class="info-brand db-mb16">伊莱克斯&nbsp;&nbsp;Electrolux&nbsp;&nbsp;FR-2-6L&nbsp;&nbsp;2x6升</div> -->
            <div class="info-num">¥ {{ citem.skuPrice }} * {{ citem.skuQuantity }}</div>
          </div>
          <div class="info-price blod">¥ {{ citem.realAmount }}</div>
        </div>
      </div>
      <div class="see-more-goods" @click="seeGoodsFun(item.id)">
        <div style="width: 300px;" />
        <DoubleRightOutlined
          style="
            font-size: 40px;
            color: #3B6652;
          "
          :class="iSeeMoreGoods === item.id ? 'icon-more-click' : 'icon-more' "
        />
        <div class="order-btn-box">
          <a-button
            v-if="[1, 2, 3, 6, 7].includes(item.status)"
            class="order-btn see-detail-btn"
            @click="seeOrderDetails(item.id)"
          >查看订单</a-button>
          <a-button
            v-if="[0].includes(item.status)"
            class="order-btn see-detail-btn"
            @click="toPayFun(item.id)"
          >支付</a-button>
          <!-- <a-button
            v-if="[2].includes(item.status)"
            class="order-btn order-btn-select"
            @click="showLigisticsFun"
          >查看物流</a-button> -->
        </div>
      </div>
    </div>
    <div v-if="!list.length" style="height: 400px;padding-top: 100px;">
      <a-empty :description="'暂无数据'" />
    </div>

    <logistics :drawer-ligistics="iShowLigistics" @closeDrawer="closeFun" />
  </div>
</template>

<script>
import { DoubleRightOutlined } from '@ant-design/icons-vue'
import logistics from '@/components/logistics'
import { getOrderItemByOrderId } from '@/api/order'
import { filterVal } from '@/utils'
export default {
  name: 'OrderItems',
  components: {
    logistics,
    DoubleRightOutlined
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      iShowLigistics: false,
      iSeeMoreGoods: '',
      itemsList: [],
      statusList: [
        { label: '待付款', value: 0 },
        { label: '待发货', value: 1 },
        { label: '已发货', value: 2 },
        { label: '已完成', value: 3 },
        { label: '已关闭', value: 4 },
        { label: '无效订单', value: 5 },
        { label: '分拣订单', value: 6 },
        { label: '商品出库', value: 7 }
      ]
    }
  },
  methods: {
    toPayFun(id) { // 去支付
      this.$router.push({
        path: '/order/orderPay',
        query: {
          orderId: id
        }
      })
    },
    seeGoodsFun(id) {
      if (this.iSeeMoreGoods === id) this.iSeeMoreGoods = ''
      else {
        this.getOrderItem(id)
        this.iSeeMoreGoods = id
      }
    },
    showLigisticsFun() {
      this.iShowLigistics = !this.iShowLigistics
    },
    closeFun() {
      this.iShowLigistics = false
    },
    getOrderItem(id) {
      const data = {
        orderId: id
      }
      getOrderItemByOrderId(data).then(res => {
        this.itemsList = res.data
      })
    },
    seeOrderDetails(val) {
      this.$router.push({ path: '/order/orderDetails', query: { id: val }})
    },
    filterVal
  }
}
</script>
<style lang='less' scoped>
.orderItems {
  // height: 285px;
  // padding: 40px 48px;
  padding-top: 40px;
  border-bottom: 1px solid #F3F3F3;
  &:last-child {
    border-bottom: 0px;
  }
  .orderno {
    padding: 24px 48px;
    font-weight: bold;
    color: #333333;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F8F8F8;

    & > span {
      color: #3B6652;
      font-size: 20px;
      display: block;
      width: 590px;
    }
    .goods-num {
      flex: 1;
      // text-align: center;
    }
  }
  .see-more-goods {
    width: 100%;
    height: 89px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all .2s linear;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    padding: 0 48px;
    .icon-more {
      transform: rotate(90deg);
      transition: all .2s linear;
    }

    &:hover {
      transform: translateZ(10px);
    }
    .icon-more-click {
      transform: rotate(-90deg);
      transition: all .2s linear;
    }
  }
  .order-info {

    .order-info-item {
      width: 100%;
      height: 209px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 48px;

      .info-price {
        // width: 60px;
        font-size: 20px;
        font-weight: bold;
        line-height: 48px;
      }
    }
    .order-item-index {
      font-size: 20px;
      font-weight: bold;
      line-height: 48px;
      color: #333333;
      margin-right: 72px;
    }

    .order-img {
      width: 128px;
      height: 128px;
      margin-right: 24px;

      &>img {
        width: 100%;
        height: 100%;
      }
    }
    .info-box {
      // display: flex;
      // width: 100%;
      // justify-content: space-around;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      flex: 1;
      .blod {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .order-btn-box {
    width: 300px;
    text-align: right;

    .order-btn {
      margin-left: 24px;
      width: 120px;
      height: 40px;
      border-radius: 24px;
      border: 0;
      color: #999999;
      background: #E5E5E5;
      cursor: pointer;
    }
    .see-detail-btn {
      &:hover {
        border: 1px solid #3B6652;
        color: #3B6652;
        background: #fff;
      }
    }
    .order-btn-select {
      color: #fff;
      background-color: #3B6652;
    }
  }
}
</style>
