<template>
  <div class="accound container">
    <ul class="accound-list">
      <li>
        <div class="accound-item default-item">
          <div class="itemWidth author-box">

            <img src="@/assets/images/icons/icon-user.png" alt="author">
            <span>姓名</span>
            <!-- 默认 -->
            <span v-show="isChangeIndex != 1">{{ userInfo.username }}</span>
            <!-- 编辑 -->
            <a-input v-show="isChangeIndex === 1" v-model:value="userInfo.username" class="ainput" type="text" placeholder="厨窗加" />
          </div>
          <div class="itemWidth">
            <a-radio-group v-model:value="userInfo.gender" name="radioGroup">
              <a-radio :value="1" :disabled="isChangeIndex != 1">男</a-radio>
              <a-radio :value="0" :disabled="isChangeIndex != 1">女</a-radio>
            </a-radio-group>
          </div>
          <div class="itemWidth author-operation">
            <a-button
              v-show="isChangeIndex != 1"
              class="abutton"
              @click="isChangeIndex = 1"
            >
              修改
            </a-button>
            <a-button
              v-show="isChangeIndex === 1"
              class="abutton db-mr10"
              @click="isChangeIndex = 0"
            >
              取消
            </a-button>
            <a-button
              v-show="isChangeIndex === 1"
              class="abutton"
              @click="confirmFun(1)"
            >
              确认
            </a-button>
          </div>
        </div>

      </li>
      <li>
        <div class="accound-item">
          <div class="itemWidth author-box">
            <img src="@/assets/images/icons/icon-phone.png" alt="author">
            <span>电话</span>
            <!-- 默认 -->
            <span v-show="isChangeIndex != 2">{{ userInfo.mobile }}</span>
            <!-- 编辑 -->
            <a-input v-show="isChangeIndex === 2" class="ainput" type="text" placeholder="13133211675" />
          </div>
          <div class="itemWidth">
            <span v-show="isChangeIndex === 2" class="db-mr8">验证码</span>
            <!-- 编辑 -->
            <a-input v-show="isChangeIndex === 2" class="ainput" type="text" placeholder="yzm" />
          </div>
          <div class="itemWidth author-operation">
            <!-- <a-button
              v-show="isChangeIndex != 2"
              class="abutton"
              @click="isChangeIndex = 2"
            >
              修改
            </a-button> -->
            <!-- <a-button
              v-show="isChangeIndex === 2"
              class="abutton"
              @click="isChangeIndex = 0"
            >
              确认
            </a-button> -->
          </div>
        </div>
      </li>
      <li>
        <div class="accound-item">
          <div class="itemWidth author-box">
            <img src="@/assets/images/icons/icon-mail.png" alt="mail">
            <span>邮箱</span>
            <!-- 默认 -->
            <span v-show="isChangeIndex != 3">{{ userInfo.email ? userInfo.email : '暂无' }}</span>
            <!-- 编辑 -->
            <a-input v-show="isChangeIndex === 3" v-model:value="userInfo.email" class="ainput" type="text" placeholder="邮箱" />
          </div>
          <div class="itemWidth">
            <!-- <span v-show="isChangeIndex === 3" class="db-mr8">验证码</span> -->
            <!-- 编辑 -->
            <!-- <a-input v-show="isChangeIndex === 3" class="ainput" type="text" placeholder="yzm" /> -->
          </div>
          <div class="itemWidth author-operation">
            <a-button
              v-show="isChangeIndex != 3"
              class="abutton"
              @click="isChangeIndex = 3"
            >
              修改
            </a-button>
            <a-button
              v-show="isChangeIndex === 3"
              class="abutton db-mr10"
              @click="isChangeIndex = 0"
            >
              取消
            </a-button>
            <a-button
              v-show="isChangeIndex === 3"
              class="abutton"
              @click="confirmFun(3)"
            >
              确认
            </a-button>
          </div>
        </div>
      </li>
      <li>
        <div class="accound-item">
          <div class="itemWidth author-box">
            <img src="@/assets/images/icons/icon-wechat.png" alt="mail">
            <span>微信</span>
            <!-- 默认 -->
            <span>暂未绑定微信</span>
          </div>
          <div class="itemWidth" />
          <div class="itemWidth author-operation">
            <a-button
              class="abutton"
            >
              <!-- 4 -->
              绑定
            </a-button>
          </div>
        </div>
      </li>

      <!-- 地址 -->
      <li>
        <div class="accound-item">
          <div class="itemWidth author-box">
            <img src="@/assets/images/icons/icon-position.png" alt="position">
            <span>地址</span>
            <!-- 默认 -->
            <span @click="ShowAddressList = true"><a href="javascript:void(0);">查看收获地址</a> </span>
          </div>
          <div class="itemWidth" />
          <div class="itemWidth author-operation">
            <a-button
              v-show="isChangeIndex != 5"
              class="abutton"
              @click="ShowPositionModel = true"
            >
              添加
            </a-button>
          </div>
        </div>
        <!-- <ul class="more-position">
          <li v-for="item in addressList" :key="item.id" class="db-mb10">
            <div class="more-position-item db-mb10">
              <span class="position-item-title">收货人</span>
              <span>{{ item.name }}  {{ item.phone }} </span>
            </div>
            <div class="more-position-item">
              <span class="position-item-title">详细地址</span>
              <span>{{ item.province }},{{ item.city }},{{ item.region }},{{ item.detailAddress }}</span>

            </div>
          </li>
        </ul> -->
      </li>

      <!-- 密码 -->
      <li>
        <div class="accound-item">
          <div class="itemWidth author-box">
            <img src="@/assets/images/icons/icon-lock.png" alt="lock">
            <span>密码</span>
            <!-- 默认 -->
            <span v-show="isChangeIndex != 6">{{ userInfo.password ? '******' : '暂未设置密码' }}</span>
            <!-- 编辑 -->
            <a-input v-show="isChangeIndex === 6" v-model:value="newPassword" class="ainput" type="text" placeholder="新密码" />
          </div>
          <div class="itemWidth">
            <span v-show="isChangeIndex === 6" class="db-mr8">确认密码</span>
            <!-- 编辑 -->
            <a-input v-show="isChangeIndex === 6" v-model:value="nextNewPassword" class="ainput" type="text" placeholder="确认新密码" />
          </div>
          <div class="itemWidth author-operation">
            <a-button
              v-show="isChangeIndex != 6"
              class="abutton"
              @click="isChangeIndex = 6"
            >
              修改
            </a-button>
            <a-button
              v-show="isChangeIndex === 6"
              class="abutton db-mr10"
              @click="isChangeIndex = 0"
            >
              取消
            </a-button>
            <a-button
              v-show="isChangeIndex === 6"
              class="abutton"
              @click="confirmFun(6)"
            >
              确认
            </a-button>
          </div>
        </div>
      </li>
    </ul>

    <a-modal
      v-model:visible="ShowAddressList"
      :closable="false"
      :footer="null"
      :z-index="888"
      width="1320px"
      wrap-class-name="position-model"
      @ok="handleOk"
    >
      <!-- 编辑 -->
      <div class="model-content">
        <div class="position-title">收获地址列表</div>
        <ul v-if="addressList.length" class="position-list">
          <li v-for="(item, index) in addressList" :key="index" class="position-item" style="justify-content: space-between;">
            <p>
              {{ item.province }},
              {{ item.city }},
              {{ item.region }},
              {{ item.detailAddress }}
              <span class="db-ml20">{{ item.name }}</span>
              <span class="db-ml20">{{ item.phone }}</span>
            </p>
            <div>
              <a-button class="abutton db-mr20" @click="delAddress(item.id)">删除</a-button>
              <a-button class="abutton" @click="updateAddress(item)">修改</a-button>
            </div>
          </li>
        </ul>
        <div v-if="!addressList.length" style="height: 200px;">
          <a-empty :description="'暂无数据'" />
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model:visible="ShowPositionModel"
      :closable="false"
      :footer="null"
      :z-index="999"
      width="1320px"
      wrap-class-name="position-model"
      @ok="handleOk"
      @cancel="addressCancle"
    >
      <!-- 编辑 -->
      <div class="model-content">
        <div class="position-title">{{ addressForm.id ? '修改' : '新增' }}收获地址</div>
        <ul class="position-list">
          <li class="position-item">
            <span>联系人</span>
            <a-input v-model:value="addressForm.name" class="position-input" placeholder="联系人" />
          </li>
          <li class="position-item">
            <span>手机号</span>
            <a-input v-model:value="addressForm.phone" class="position-input" placeholder="手机号" />
          </li>
          <li class="position-item">
            <span>选择省/市/区</span>
            <div>
              <a-select v-model:value="provinceData" style="width: 140px" @change="handleProvinceChange">
                <a-select-option v-for="province in provinceList" :key="province.name">
                  {{ province.name }}
                </a-select-option>
              </a-select>
              <a-select v-model:value="cityData" style="width: 140px" @change="handleCityChange">
                <a-select-option v-for="citys in cityList" :key="citys.name">
                  {{ citys.name }}
                </a-select-option>
              </a-select>
              <a-select v-model:value="districtData" style="width: 140px">
                <a-select-option v-for="district in districtList" :key="district.name">
                  {{ district.name }}
                </a-select-option>
              </a-select>
            </div>
          </li>
          <li class="position-item">
            <span>详细地址</span>
            <a-input v-model:value="addressForm.detailAddress" class="position-input" placeholder="详细地址" />
          </li>
          <li class="position-item">
            <span>邮编</span>
            <a-input v-model:value="addressForm.postCode" class="position-input" placeholder="邮编" />
          </li>
        </ul>
        <div class="is-default">
          <div>设为默认</div>
          <a-checkbox v-model:checked="addressForm.defaultStatus" />
        </div>
        <div class="position-btn">
          <a-button
            class="btn-position"
            @click="ConfirmFun"
          >
            确认
          </a-button>
          <a-button
            class="btn-position"
            @click="addressCancle"
          >
            取消
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { saveAdderss, listAll, updateMember, updateAdderss, deleteAdderss } from '@/api/user'
// import axios from 'axios'
import { jsonp } from 'vue-jsonp'

const addressForm = {
  name: '',
  phone: '',
  detailAddress: '',
  postCode: '',
  defaultStatus: false
}
export default {
  name: 'Accound',
  components: {
  },
  data() {
    return {
      isChangeIndex: 0,
      genderChecked: '',
      ShowPositionModel: false,
      city: [],
      addressForm: Object.assign({}, addressForm),
      addressList: [], // 收获地址
      provinceList: [],
      provinceData: '',
      cityList: [],
      cityData: '',
      districtList: [],
      districtData: '',
      emailReg: new RegExp('^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$'),
      newPassword: '',
      nextNewPassword: '',
      ShowAddressList: false
    }
  },
  computed: {
    ...mapState({
      User_ID: state => state.user.id,
      address: state => state.user.address,
      userInfo: state => state.user.userInfo
    })
  },
  mounted() {
    this.getAddressList()
    this.getCity('')
  },
  methods: {
    updateAddress(item) {
      this.ShowPositionModel = true
      this.addressForm = Object.assign({}, item)
      this.provinceData = item.province
      this.cityData = item.city
      this.districtData = item.region
      this.addressForm.defaultStatus = this.addressForm.defaultStatus === 1
    },
    delAddress(id) {
      const data = []
      data.push(id)
      deleteAdderss(data).then(res => {
        this.$message.success('删除成功')
        this.getAddressList()
      })
    },
    confirmFun(type) {
      const data = {
        id: this.User_ID
      }
      if (type === 1) {
        data.gender = this.userInfo.gender
        data.username = this.userInfo.username
        this.updateMemberFun(data)
      }
      if (type === 3) {
        if (this.userInfo.email && this.emailReg.test(this.userInfo.email)) {
          data.email = this.userInfo.email
          this.updateMemberFun(data)
        } else {
          this.$message.error('请输入正确的邮箱')
          return
        }
      }
      if (type === 6) {
        if (!this.newPassword || this.newPassword.length < 6 || this.newPassword.length > 18) {
          this.$message.error('请输入正确的密码')
          return
        } else if (this.newPassword !== this.nextNewPassword) {
          this.$message.error('两次密码不一致')
          return
        } else {
          data.password = this.newPassword
          this.updateMemberFun(data)
        }
      }
      this.isChangeIndex = 0
    },
    handleProvinceChange(val) {
      // this.provinceData = val
      this.getCity(val)
    },
    handleCityChange(val) {
      // this.cityData = val
      this.getCity(val)
    },
    updateMemberFun(data) {
      updateMember(data).then(res => {
        this.$message.success('修改成功')
        this.$store.dispatch('user/getUserInfo')
      })
    },
    ConfirmFun() {
      if (!this.User_ID) {
        this.$message.error('没有用户ID')
        return
      }
      if (!this.provinceData || !this.cityData || !this.districtData) {
        this.$message.error('请选择收货地址')
        return
      }
      if (!this.checkAdderssForm()) {
        this.$message.error('必填项不能为空')
        return
      }
      const data = {
        memberId: this.User_ID,
        name: this.addressForm.name,
        phone: this.addressForm.phone,
        province: this.provinceData,
        city: this.cityData,
        region: this.districtData,
        detailAddress: this.addressForm.detailAddress,
        postCode: this.addressForm.postCode,
        defaultStatus: this.addressForm.defaultStatus ? 1 : 2
      }
      if (this.addressForm.id) {
        data.id = this.addressForm.id
        this.editAddressFun(data)
      } else {
        this.saveAdderssFun(data)
      }
    },
    saveAdderssFun(data) {
      saveAdderss(data).then(res => {
        if (res.code === 0) {
          this.$message.success('添加地址成功')
          this.addressCancle()
          this.getAddressList()
          const path = sessionStorage.getItem('TOADDRESS')
          const _this = this
          if (path) {
            this.$confirm({
              title: '是否返回订单结算页，继续购买',
              okText: '确认',
              okType: 'danger',
              cancelText: '取消',
              centered: true,
              onOk() {
                sessionStorage.removeItem('TOADDRESS')
                _this.$router.go(-1)
              },
              onCancel() {
                console.log('Cancel')
              }
            })
          }
        }
      })
    },
    editAddressFun(data) {
      updateAdderss(data).then(res => {
        if (res.code === 0) {
          this.$message.success('修改地址成功')
          this.addressCancle()
          this.getAddressList()
        }
      })
    },
    addressCancle() {
      this.addressForm = Object.assign({}, addressForm)
      this.city = []
      this.provinceData = ''
      this.cityData = ''
      this.districtData = ''
      this.ShowPositionModel = false
    },
    handleOk() {
      this.addressForm = Object.assign({}, addressForm)
      this.city = []
      this.provinceData = ''
      this.cityData = ''
      this.districtData = ''
      this.ShowPositionModel = false
    },
    checkAdderssForm() {
      if (!this.addressForm.name) return false
      else if (!this.addressForm.phone) return false
      else if (!this.addressForm.postCode) return false
      else if (!this.addressForm.detailAddress) return false
      else return true
    },
    getCity(city = '') {
      const key = '59474777dbe15ac19d9ae454eb22afed'
      jsonp(`https://restapi.amap.com/v3/config/district?key=${key}&subdistrict=${2}&keywords=${city}`).then(res => {
        const districts = res.districts
        if (districts.length) {
          if (districts[0].level === 'country') this.provinceList = districts[0].districts
          if (districts[0].level === 'province') this.cityList = districts[0].districts
          if (districts[0].level === 'city') this.districtList = districts[0].districts
        }
      })
      // axios.get(`https://restapi.amap.com/v3/config/district?key=${key}&subdistrict=${2}&keywords=${city}`).then(res => {
      //   const districts = res.data.districts
      //   if (districts.length) {
      //     if (districts[0].level === 'country') this.provinceList = districts[0].districts
      //     if (districts[0].level === 'province') this.cityList = districts[0].districts
      //     if (districts[0].level === 'city') this.districtList = districts[0].districts
      //   }
      // })
      // const url = `/v3/config/district?key=${key}&subdistrict=2&keywords=${city}`
      // axios.get(url).then(res => {
      //   const districts = res.data.districts
      //   if (districts.length) {
      //     if (districts[0].level === 'country') this.provinceList = districts[0].districts
      //     if (districts[0].level === 'province') this.cityList = districts[0].districts
      //     if (districts[0].level === 'city') this.districtList = districts[0].districts
      //   }
      // })
    },
    getAddressList() {
      if (this.User_ID) {
        listAll(this.User_ID).then(res => {
          this.addressList = res.data
          this.$store.dispatch('user/changeAddress', res.data)
        })
      }
    }
  }
}

</script>
<style lang='less'>
.accound {

  .accound-list {
    margin-bottom: 58px;

    & > li {
      width: 100%;
      min-height: 120px;
      border-bottom: 1px solid #F3F3F3;

    }
    .more-position {
      margin-bottom: 20px;

      & > li {
        padding-left: 84px;
      }
      .position-item-title {
        display: inline-block;
        width: 80px;
      }
    }

    .accound-item {
      height: 120px;
      display: flex;
      align-items: center;

      .author-box {
        color: #666666;
        font-weight: 400;

        & > img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          vertical-align: bottom;
        }

        & > span {
          margin-right: 24px;
          vertical-align: bottom;
        }

      }
      .ainput {
        outline: none;
        border: none;
        border-bottom: 1px solid #d9d9d9;
        width: 300px;
      }
      .author-operation {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  :deep(.ant-radio-checked .ant-radio-inner) {
    border-color: #999 !important;

    &::after {
      background-color: @bg-color !important;
    }
  }

  .itemWidth {
    width: 440px;
  }

  .abutton {
    width: 56px;
    height: 24px;
    border-radius: 12px;
    background: @bg-color;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
  }

}
.position-model {

  .ant-modal {
    top: 140px;
  }
  .ant-modal-content {
    min-height: 423px;
  }
  .ant-modal-body {
    padding: 64px 160px;

    .model-content {

      .position-list {

        .position-item {
          border-bottom: 1px solid #F3F3F3;
          padding: 16px 0;
          display: flex;
          align-items: center;

          &>span {
            width: 140px;
            text-align: left;
            color: #666666;
            font-weight: bold;
          }
          &>span::after {
            content: '*';
            color: red;
          }
          .cascader {

            .ant-cascader-input.ant-input {
              border: 0;
              width: 400px;
              padding: 0;
              height: 35px;
            }
          }
          .position-input {
            border: 0;
            width: 400px;
            padding: 0;
            height: 35px;
          }
        }
      }

      .is-default {
        display: flex;
        margin-top: 40px;

        & > div {
          width: 140px;
          text-align: left;
          color: #666666;
          font-weight: bold;
        }
      }

      .position-title {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 40px;
      }

      .position-btn {
        margin-top: 40px;

        .btn-position {
          width: 173px;
          height: 46px;
          background: #3B6652;
          border-radius: 32px;

          &:first-child {
            margin-right: 148px;
            color: #fff;
          }
          &:last-child {
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
          }
        }
      }
    }
  }
}

</style>
