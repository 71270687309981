<template>
  <div class="UserInfo">
    <userState />
    <div class="user-core container">
      <ul class="user-core-bar" @click="clickFun">
        <li class="user-core-bar-item item1" :class="isClickCoreIndex === 1 ? 'is-click-core' : ''">账户中心</li>
        <li class="user-core-bar-item item2" :class="isClickCoreIndex === 2 ? 'is-click-core' : ''">订单中心</li>
        <li class="user-core-bar-item item3" :class="isClickCoreIndex === 3 ? 'is-click-core' : ''">发票中心</li>
      </ul>
    </div>
    <keep-alive>
      <component :is="componentName" />
    </keep-alive>
    <toTop />
  </div>
</template>

<script>
import userState from '@/views/user/components/userState'
import Account from '@/views/user/account'
import OrderCenter from '@/views/user/orderCenter'
import InvoiceCenter from '@/views/user/InvoiceCenter'
import toTop from '@/components/toTop'

export default {
  name: 'UserInfo',
  components: {
    userState,
    Account,
    OrderCenter,
    InvoiceCenter,
    toTop
  },

  data() {
    return {
      isClickCoreIndex: 1,
      componentName: 'Account'
    }
  },
  created() {
    if (sessionStorage.getItem('usercom')) {
      this.componentName = sessionStorage.getItem('usercom')
      if (this.componentName === 'Account') this.isClickCoreIndex = 1
      if (this.componentName === 'OrderCenter') this.isClickCoreIndex = 2
      if (this.componentName === 'InvoiceCenter') this.isClickCoreIndex = 3
    }
    if (this.$route.query) {
      if (this.$route.query.comtype === 'InvoiceCenter') {
        this.isClickCoreIndex = 3
        this.componentName = 'InvoiceCenter'
      } else if (this.$route.query.comtype === 'Account') {
        this.isClickCoreIndex = 1
        this.componentName = 'Account'
      }
    }
  },
  methods: {
    clickFun(e) {
      if (e.target.className.indexOf('item1') > 0) {
        this.isClickCoreIndex = 1
        this.componentName = 'Account'
        sessionStorage.setItem('usercom', 'Account')
      } else if (e.target.className.indexOf('item2') > 0) {
        this.isClickCoreIndex = 2
        this.componentName = 'OrderCenter'
        sessionStorage.setItem('usercom', 'OrderCenter')
        sessionStorage.setItem('orderItemBar', '全部订单')
      } else {
        this.isClickCoreIndex = 3
        this.componentName = 'InvoiceCenter'
        sessionStorage.setItem('usercom', 'InvoiceCenter')
      }
    }
  }
}
</script>
<style lang='less' scoped>
.UserInfo {

  .user-core {

    .user-core-bar {

      display: flex;
      align-items: center;

      .user-core-bar-item {
        width: 440px;
        height: 48px;
        text-align: center;
        background-color: #F3F3F3;
        line-height: 48px;
        font-size: 18px;
        transition: all .3s linear;
        cursor: pointer;
      }

      .is-click-core {
        background-color: @bg-color;
        color: #FFFFFF;
      }
    }

  }
}
</style>
